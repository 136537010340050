<template>
  <transition name="fade">
    <signin v-if="isLoginOpen" @close="onCloseSignin" @signup="isLoginOpen = false;isSignupOpen = true"/>
  </transition>

  <transition name="fade">
    <TMmodal v-show="TMOpen" :isOpen="TMOpen" :type="'header'" @close="onCloseTM"/>
  </transition>

  <transition name="fade">
    <join v-if="isSignupOpen" @close="onCloseSignup"/>
  </transition>

  <transition name="fade">
    <join-complete v-if="isSignupCompleteOpen" @close="isSignupCompleteOpen = false;isLoginOpen = true"/>
  </transition>

  <transition name="fade">
    <new-message v-if="isUnReadMessage" />
  </transition>

  <header v-if="gameCount" id="mainheader" :class="{ active01: selectedMenu === 'livecasinoSec', active02: selectedMenu === 'hotelcasinoSec', active03: selectedMenu === 'slotSec' }">
    <div class="head_info">
      <div class="hinfo_wrap">
        <div class="topbanner">
          <div class="topbannerin">
              <div class="speakerIcon"><img src="@/assets/img/icon_mega.svg"/></div>
              <span class="">{{$t('front.header.msg')}}</span>
          </div>
        </div>
      </div>
    </div>
    <ul class="header">
      <li class="logowrap">
        <a @click="goPageByName('main')">
          <img src="@/assets/img/boracaylogo.png" />
        </a>
      </li>

      <li class="menuWrap">
        <div class="naviwrap" >
          <ul class="navi">
            <li @click="handleMenuClick('livecasinoSec')"><em></em>라이브카지노</li>
            <li @click="handleMenuClick('hotelcasinoSec')"><em></em>호텔카지노</li>
            <li @click="handleMenuClick('slotSec')"><em></em>슬롯</li>
            <li @click="goPageByName('notice')"><em></em>공지사항</li>
            <li @click="goPageByName('csCenter')"><em></em>고객센터</li>
            <li @click="goPageByName('charge')"><em></em>입/출금</li>
          </ul>
          <template v-if="!userData">
            <div class="loginWrap">
              <ui-button :className="'joinbtn headerbtn'" :text="$t('front.common.signup')" @click="onClickSignup"/>
              <ui-button :className="'loginbtn headerbtn'" :text="$t('front.gnb.login')" @click="onClickLogin"/>
            </div>
          </template>
          <template v-else>
            <ul class="coninpoint" v-if="userData">
              <!--li class="exBtnw"><button class="exBtn" @click="onClickTM()">통합머니 전환</button></li-->
              <li><img src="@/assets/img/icon_cash.png" /><span>{{thousand(userData.cashAmt ? userData.cashAmt.toString(): '0')}}</span><button class="headerbtn_coinplus" @click="goPageByName('charge')">+</button></li>
              <li style="cursor: pointer" @click="goPageByName('point')"><img src="@/assets/img/icon_point.png" /><span class="bgnc">{{thousand(userData.pointAmt ? userData.pointAmt.toString() : '0')}}</span><button class="headerbtn_coinplus">+</button></li>
            </ul>
          </template>
        </div>
      </li>
    </ul>

  </header>

  <div v-if="userData" id="sideNav">
    <ul id="sideNavTop">
      <li class="side_user" v-if="userData" @click="goPageByName('mypage')">
        <span><span class="sideicon"></span></span>
        <em>{{userData.memNick}}</em>
      </li> <!--회원이름-->
      <li class="side_bet" @click="goPageByName('bettingList')">
        <span><span class="sideicon"></span></span>
        <em>베팅내역</em>
      </li> <!--베팅내역-->
      <li class="side_inout" @click="goPageByName('charge')">
        <span><span class="sideicon"></span></span>
        <em>입출금내역</em>
      </li> <!--입출금내역-->
      <li class="side_point" @click="goPageByName('point')">
        <span><span class="sideicon"></span></span>
        <em>포인트</em>
      </li> <!--포인트-->
      <li class="side_msg" @click="goPageByName('messageList')">
        <span><span class="sideicon"></span></span>
        <em>쪽지({{thousand(unReadMessageCount ? unReadMessageCount.toString() : '0')}})</em>
      </li> <!--쪽지-->
      <li class="side_set" @click="goPageByName('info')">
        <span><span class="sideicon"></span></span>
        <em>정보수정</em>
      </li> <!--정보수정-->
      <li class="side_partner" v-if="userData.isPartnerMember && userData.isPartnerMember === 'Y'">
        <a :href="'/BG9'" target="_blank">
        <span><span class="sideicon"></span></span>
        <em>파트너</em>
        </a>
      </li> <!--파트너-->
    </ul>
    <div id="sideNavBottom">
      <div class="logindropdown" @mouseover="gnbMenuOpen('lang')">
          <button class="logindropbtn langbtn">
            <span class="langsp lang" v-if="$i18n.locale === 'ko'"><img src="@/assets/img/korea.png" /><span></span></span>
            <span class="langsp lang" v-if="$i18n.locale === 'en'"><img src="@/assets/img/eng.png" /><span></span></span>
            <span class="langsp lang" v-if="$i18n.locale === 'vn'"><img src="@/assets/img/vn.svg" /><span></span></span>
          </button>
          <transition name="fade">
            <div class="logindropdown-content lang" v-if="showMenuLang">
              <a href="#" :class="{'on': $i18n.locale === 'ko'}" @click="changeLocale('ko')"><img src="@/assets/img/korea.png" /><span></span></a>
              <a href="#" :class="{'on': $i18n.locale === 'en'}" @click="changeLocale('en')"><img src="@/assets/img/eng.png" /><span></span></a>
              <a href="#" :class="{'on': $i18n.locale === 'vn'}" @click="changeLocale('vn')"><img src="@/assets/img/vn.svg" /><span></span></a>
            </div>
          </transition>
        </div>
        <div class="logoutbtnWrap" v-if="userData">
          <ui-button :className="'logoutbtn'" @click="signOut"/>
        </div>
    </div>
  </div>

</template>

<script>

import UiButton from '@/components/ui/UiButton'

import { mapState } from 'vuex'
import Join from '@/components/member/Join'
import JoinComplete from '@/components/member/JoinComplete'
import Signin from '@/components/member/Signin'
import TMmodal from '@/components/common/TotalMoney'
import { TRIPLE_GAME_LIST } from '@/libs/constants'
import NewMessage from '@/components/member/Message.vue'

export default {
  name: 'Header',
  components: {
    NewMessage,
    Signin,
    JoinComplete,
    Join,
    UiButton,
    TMmodal
  },
  data () {
    return {
      menuList: [],
      TMOpen: false,
      isLoginOpen: false,
      isSignupOpen: false,
      isSignupCompleteOpen: false,
      showMenu: false,
      showMenuMoney: false,
      showMenuLang: false,
      isAlertOpen: false,
      isGameOpen: false,
      isSlotOpen: false,
      isSportsOpen: false,
      isMiniOpen: false,
      isCashOpen: false,
      tripleGameList: TRIPLE_GAME_LIST
    }
  },
  watch: {
    $route: {
      handler (current) {
        console.log('$route name', this.$route)
        this.gnbReset()
      }
    }
  },
  props: ['selectedMenu'],
  created () {
    this.setMenu()
    this.getHeadMsgList()
  },
  computed: {
    ...mapState([
      'userData',
      'coinAmt',
      'commonCodeByOrder',
      'gameCount',
      'unReadMessageCount'
    ]),
    isUnReadMessage () {
      return this.unReadMessageCount && this.unReadMessageCount && this.unReadMessageCount !== '0' && this.$route.name.indexOf('message') === -1 && this.$route.name.indexOf('msg') === -1
    }
  },
  methods: {
    slotOpen (event, groupCode, codeName, code) {
      this.emitter.emit(event, { groupCode, codeName, code })
    },
    handleMenuClick (menu) {
      this.$emit('menu-click', menu)
    },
    gnbReset () {
      this.isGameOpen = false
      this.isSlotOpen = false
      this.isSportsOpen = false
      this.isMiniOpen = false
      this.isCashOpen = false
      this.showMenu = false
      this.showMenuLang = false
    },
    gnbMenuOpen (type) {
      this.gnbReset()
      if (type === 'game') {
        this.isGameOpen = true
      } else if (type === 'slot') {
        this.isSlotOpen = true
      } else if (type === 'cash') {
        this.isCashOpen = true
      } else if (type === 'mypage') {
        this.showMenu = true
      } else if (type === 'lang') {
        this.showMenuLang = true
      } else if (type === 'sport') {
        this.isSportsOpen = true
      } else if (type === 'minigame') {
        this.isMiniOpen = true
      }
    },
    getHeadMsgList () {
      // getHeadMsg({}).then(response => {
      //   console.log(response)
      // })
    },
    setMenu () {
      const list = this.$router.getRoutes()
      for (let i = 0, iLen = list.length; i < iLen; i++) {
        const item = list[i]
        if (item.meta && item.meta.gnb) {
          item.isSubOpen = false
          this.menuList.push(item)
        }
      }
    },
    onClickGame (code, name) {
      this.gnbReset()
      if (code) {
        // minigame

        if (code === 'mg') {
          this.$router.push({ name: name })
        } else {
          this.onSelectGame(code)
        }
      } else {
        this.onCheck('준비 중입니다.')
      }
    },
    onClickGnb (item) {
      const name = item.name
      const meta = item.meta
      if (meta.game) {
        this.onSelectGame(meta.gameCode)
      } else {
        this.$router.push({ name: name })
      }
    },
    async onClickTM () {
      const confirmMessage = '통합머니 전환을 진행하시겠습니까?'
      const confirmResult = await this.onConfirm(confirmMessage)
      if (confirmResult) {
        this.TMOpen = true
      }
    },
    onCloseTM () {
      this.TMOpen = false
    },
    onClickLogin () {
      this.isLoginOpen = true
      this.isSignupOpen = false
    },
    onClickSignup () {
      this.isLoginOpen = false
      this.isSignupOpen = true
    },
    onCloseSignin (value) {
      this.isLoginOpen = false
      if (value === 'join') {
        this.isSignupOpen = true
      }
      if (value === 'signin') {
        // location.reload()
      }
    },
    onCloseSignup (status) {
      this.isSignupOpen = false
      if (status) {
        this.isSignupCompleteOpen = true
      }
    }
  }
}
</script>

<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/common.css"></style>
